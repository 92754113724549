import { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import type { Document as PrismicDocument } from '@prismicio/client/types/documents';
import { ProjectsState } from './store/projects.module';
import { WeatherAnalyticsResponse } from './util.tidy-weather-analysis';
export { PrismicDocument };

// ========================================
// SIMPLE ALIAS TYPES
// ========================================

type DateStr = string;
type EntityID = string;

// ========================================
// LOCATION
// ========================================

export type LngLat = [number, number];

export type MBAddressObj = GeocodeFeature;

export interface CommunicationPrefs {
    informOfArticlePublication: boolean;
    informOfNewApplicationFeature: boolean;
    notifyOfCommentOnObservation: boolean;
    notifyOfNeedToMonitorExtremeWeatherOrClimateTrend: boolean;
    notifyOfNewInvestigationICanParticipateIn: boolean;
    sendTipsAndTraining: boolean;
    sendWeeklyObservationDigest: boolean;
    smsPermitted: boolean;
    notifyMethod: NotifyMethods[];
}

export interface AddressComponents {
    text?: string;
    country?: string;
    countryCode?: string;
    state?: string;
    stateCode?: string;
    district?: string;
    city?: string;
    place?: string;
    neighborhood?: string;
    streetName?: string;
    streetNumber?: string;
    zipcode?: string;
}

// ========================================
// APP DATA CONSTRUCTS
// ========================================
export interface PostParameters {
    format?: null | 'geojson' | 'csv';
    uniqueId?: string;
    userId?: string;
    investigationId?: string | string[];
    regionId?: string;
    lngLat?: LngLat;
    distance?: string;
    withImage?: boolean;
    structuredQuestion?: string[];
    fromDate?: string;
    toDate?: string;
    search?: string;
    sortBy?: string;
    page?: number;
    limit?: number;
}

export interface PostQueryParameters {
    userId?: string;
    investigationId?: string;
    regionId?: string;
    near?: string; // "lng,lat,distance", e.g. "-87.63,41.88,15mi"
    fromDate?: string;
    toDate?: string;
    search?: string;
    sortBy?: string;
    page?: string;

    /** @deprecated Use `near` instead */
    lat?: string;
    /** @deprecated Use `near` instead */
    lon?: string;
    /** @deprecated Use `near` instead */
    distance?: string;
}

export interface DraftPost {
    draftKey: string,
    textBody: string,
    weird: boolean,
    photos: File[],
    date: string,
    time: string,
    coordinates: LngLat | null,
    location: MBAddressObj | null,
    investigationIds: string[],
    structuredAnswers: { [questionId: string]: any },
}

export interface PostCreationPayload {
    retry: number;
    textBody: string;
    weird: boolean;
    media: { id: Photo['id'] | Video['id'], type: 'image' | 'video' }[];
    observedAt: string,
    lng: number;
    lat: number;
    addressComponents: AddressComponents;
    investigation: string | string[]; // Either is allowed for backward compatibility.
    structuredAnswers: StructuredAnswer[];
}

export interface AlertData {
    message: string;
    type: 'error' | 'warning' | 'info';
    timeout?: number;
    suppressFurtherErrors?: boolean;
}

export interface Notification {
    id: EntityID;
}

// ========================================
// ENTITIES
// These are the fundamental entities
// used within ISC
// ========================================

export interface Region {
    centerGeoPointGeoJson: GeoJSON.Point;
    geoRegionGeoJson?: GeoJSON.Geometry;
    id: EntityID;
    label: string;
}

export interface ClientGroup {
    id: string;
    isInternal: boolean;
    name: string;
    logo: Photo | null;
    investigations: { id: EntityID }[];
    slug: string;
    regions: Region[];
}

export interface UserGroup {
    activeSurveyCount: number;
    admins?: UserGroupMember[];
    clientGroups: ClientGroup[];
    createdAt: string;
    id: string;
    inviteLink?: {
        token?: string;
        expiresAt?: string;
    };
    is_anonymous: boolean;
    memberCount: number;
    name: string;
    owners?: UserGroupMember[];
}

export interface UserGroupMember {
    clientGroup: ClientGroup | null;
    createdAt: string;
    id: UserGroup['id'];
    is_admin: boolean;
    is_anonymous: UserGroup['is_anonymous'];
    is_owner: boolean;
    name: UserGroup['name'];
    status: string;
    user: User | null;
}

export interface User {
    createdAt: DateStr;
    id: EntityID;
    firstName: string;
    lastName: string;
    avatar?: Photo;
    clientGroups?: ClientGroup[];
    description: string;
    fbUseAvatar: boolean;
}

export interface CurrentUser extends User {
    email: string;
    mobilePhone: string;
    communicationPreferences: CommunicationPrefs;

    addressComponents: AddressComponents;
    shortAddress: string;
    geoPoint: GeoJSON.Point;
    lat: number;
    lng: number;

    fbUserAvatar: boolean;
    fbUserId: string;
    sciStarterId: null | number; // TODO: do we want all users scistarter IDs exposed?
    userGroups: UserGroupMember[];

    userSettings?: Partial<{
        // Note `userSettings` values will all be strings.
        preferredSystemOfMeasure: 'METRIC' | 'IMPERIAL';
        lastViewedActionsPage: string;
        ignoredActionsPrompt: string;
        homeSightingsQuery: string;
        sightingsQuery: string;
        historicalWeatherPromptDismissed: string,
        mapQuery: string;
        mapLastLoaded: string;
        mapVisitedPosts: string;
    }>;
}

export interface NewUser {
    email: CurrentUser['email'];
    password: string;
    firstName: CurrentUser['firstName'];
    lastName: CurrentUser['lastName'];
    mobilePhone: CurrentUser['mobilePhone'];
    addressComponents: CurrentUser['addressComponents'];
    communicationPreferences: Partial<CurrentUser['communicationPreferences']>;
    signupSource: string;
}

export type UserSettingsKey = keyof NonNullable<CurrentUser['userSettings']>;

export interface Comment {
    id: EntityID;
    addressComponents: AddressComponents;
    createdAt: DateStr;
    deletedAt?: string;
    lat: number;
    lng: number;
    media: (Photo | Video)[] | null;
    observedAt: DateStr;
    post: EntityID;
    published: boolean;
    season: null | string;
    shortAddress: string;
    textBody: string;
    type: CommnentType;
    updatedAt: DateStr;
    user: EntityID;
    userObj: User;
    weatherData: WeatherData;
    weatherDataType: WeatherDatatype;
}

export enum QuestionDataType {
    DECIMAL = 'DECIMAL',
    NUMERIC = 'NUMERIC',
    BOOLEAN = 'BOOLEAN',
    DATETIME = 'DATETIME',
    SELECT = 'SELECT',
    TIMERANGE = 'TIMERANGE',
}

export interface StructuredQuestion {
    additionalInfo: any;
    dataType: QuestionDataType;
    description: string;
    deviceType: DeviceType;
    featured: boolean;
    id: EntityID;
    investigationId: EntityID;
    order: number;
    placeholder: string;
    promoted: boolean;
    published?: boolean;
    regionId: null | EntityID;
    title: string;
    options: { label: string, value: string }[];
    unit: string;
    warning: null | string;
    answer: null | StructuredAnswer; // NOTE: added on clientside & optional
}

export interface StructuredAnswer {
    booleanValue: null | boolean;
    createdAt: DateStr;
    datetimeValue: null | DateStr;
    featuredQuestion: boolean;
    id: EntityID;
    literalValue: null | string;
    numericValue: null | number;
    timeRangeValue: null | (string | null)[];
    observationId: EntityID;
    promotedQuestion: boolean;
    questionId: EntityID;
    updatedAt: DateStr;
    userId: EntityID;

}

export interface Investigation {
    id: EntityID;
    investigationCategory: string, // TODO
    name: string;
    backgroundImage: Photo;
    featured: boolean;
    cta: string;
    externalLinks: string[];
    investigatorCount: number;
    investigators: User[];
    postCount: number;
    slug: string;

    structuredQuestions: StructuredQuestion[];
    structuredQuestionTitle: string;

    suggestedTags: string[];
    tags: string[];
    updatedAt: DateStr;

    projects: any[];

    actionDescription: string;
    actionDescriptionMarkdown: string | null;
    actionHeader: string;

    issueDescription: string;
    issueDescriptionMarkdown: string;
    issueDescriptionHeader: string;

    regionId: string;
    regionLabel: string;
}

export interface Project {
    clientGroup: ClientGroup;
    id: string;
    investigations: Omit<Investigation, 'backgroundImage'>[];
    projectDetails: {
        goals: string;
        image: Photo | null;
        instructions: string;
        introduction: string;
        partners: {
            name: string;
            url: string,
            logo: Photo | null;
            infoShared: boolean;
        }[];
        prompts: string[];
        published: boolean;
        samplePosts: Post['id'][];
        share_precise_location: boolean;
        slug: string;
        title: string;
    };
    regions: (Region & { geoRegionGeoJson: GeoJSON.Feature })[];
}

export interface Story {
    id: number; // Not an EntityID because these are WordPress records.
    author: string;
    title: string;
    url: string;
    date: Date;
    image: string;
    image_align: string;
    content: string;
    wp_content: string;
}

export type SurveyRequest = {
    createdAt: string;
    dismissedAt: string | null;
    id: string;
    mandatory: boolean,
    message: string | null;
    survey_id: Survey['id'];
};

export type Survey = {
    closedAt?: string;
    description: string;
    id: string,
    is_published: boolean;
    is_recurring?: boolean;
    lastSurveyRequestBatch: {
        totalSurveyRequests: number;
        totalSurveyResponses: number;
    }[];
    requestRecipients: unknown[];
    settings?: {
        containsSensitiveData?: boolean;
        defaultMandatory?: boolean;
        dontPersistAnswers?: boolean;
        embed?: {
            url: string;
            height?: string; // CSS height applied to the iframe.
        };
    };
    stats?: {
        questions: (Pick<SurveyQuestion, 'id' | 'question_type' | 'title' > & {
            answers: {
                answer: unknown;
                count: number;
            }[];
        })[];
        responsesByDay: {
            count: number;
            date: string;
        }[];
    };
    surveyQuestions: SurveyQuestion[] | null;
    title: string;
    totalSurveyRequests: number;
    totalSurveyResponses: number;
    totalUsersRequested: number;
    totalUsersResponded: number;
};

// Create these as they're' defined in use.
type SurveryQuestionCondition =
    [state: 'ANSWERED_AFFIRMATIVE', ...questionIds: SurveyQuestion['id'][]]
    | [state: 'ANSWER_EQUALS', question: SurveyQuestion['id'], ...validAnswers: unknown[]]
    | [state: 'ONCE_PER_DAY'];

type BaseSurveyQuestion = {
    id: string;
    is_required: boolean;
    order: number | null;
    subtitle: string | null;
    survey_id: Survey['id'];
    title: string | null;
    on_condition: SurveryQuestionCondition | null;
};

export type TextSurveyQuestion = BaseSurveyQuestion & {
    question_type: 'text';
    options?: {
        multiline: boolean;
    };
};

export type RadioCheckboxSurveyQuestion = BaseSurveyQuestion & {
    question_type: 'checkbox' | 'radio';
    options?: {
        label: string;
        value: string;
        input?: boolean;
        multiline?: boolean;
    }[];
};

export type SurveyQuestion = TextSurveyQuestion | RadioCheckboxSurveyQuestion;

export interface Activity {
    action: ActivityType;
    action_timestamp: DateStr;
    actor: EntityID;
    actorObj?: User;
    postId: EntityID;
    postObj?: Post;
    user: EntityID;
    userObj?: User;
    commentId: EntityID;
    commentObj?: Comment;
    notification: EntityID;
    notificationObj?: Notification;
}

export interface DeviceType {
    additionalInfo?: {
        illustration?: {
            url: string;
        };
    };
    createdAt: DateStr;
    description: string;
    id: EntityID;
    label: string;
    newSensorDescription: null | string;
    parentTypeId: EntityID;
    updatedAt: DateStr;
}

export interface Device {
    additionalInfo: any;
    addresComponents: AddressComponents;
    childType?: DeviceType;
    childTypeId: EntityID; // same as childType.id
    id: EntityID;
    images: Photo[];
    label: string;
    lat: number;
    lng: number;
    type: DeviceType;
    typeId: EntityID;
    updatedAt: DateStr;
    userId: EntityID;
}

export interface Post {
    id: EntityID;
    addressComponents: AddressComponents;
    createdAt: DateStr;
    observedAt: DateStr;
    textBody: string;
    totalComments: number;
    totalLikes: number;
    weird: boolean;
    shortAddress: string;
    media: (Photo | Video)[];
    // relationships
    user: User['id'];
    userObj: User;
    photos: Photo['id'][];
    photoObjs: Photo[];
    commentObjs: Comment[];
    comments: Comment['id'][];
    investigation: Investigation['id'];
    investigationObj: Investigation | null;
    investigations: Investigation['id'][];
    investigationObjs: Investigation[];
    fuzzyLat: number;
    fuzzyLng: number;
    lat: number;
    lng: number;
    showSatelliteImage?: boolean;

    weatherData: WeatherData;
    weatherDataType: WeatherDatatype;
    weatherAnalyticsData?: WeatherAnalyticsResponse | null;
    structuredAnswers: StructuredAnswer[];
    weatherUnits: WeatherUnits;
}

type MuxAssetInfo = {
    status: 'PROCESSING' | 'ERROR' | 'READY';
    asset_id: string;
    playback_id: string;
};

export interface Photo {
    mediaType: 'image';
    additionalInfo?: {
        mux?: MuxAssetInfo; // TODO: This shouldn't be here.
    };
    id: EntityID;
    dirPath: string;
    format: string; // jpg, png, jpeg, etc
    height: number;
    width: number;
    secure_url: string;
}

export interface Video {
    mediaType: 'video';
    id: EntityID;
    width: number;
    height: number;
    additionalInfo: {
        mux: MuxAssetInfo;
    };
}

// ========================================
// MISCELLANEOUS
// ========================================

type WeatherDatatype = 'darksky.net';

type CommnentType = 'general-comment';

type NotifyMethods = 'email' | 'app' | 'sms';

type ActivityType = 'created-nearby-post' | 'liked-post' | 'commented-on-post' | 'created-post' | 'alert';

export interface WeatherDataRecently {
    lastXHours: number;
    precip: number;
    snow: number;
}

export interface WeatherData {
    offset: number;
    timezone: string;
    currently: {
        apparentTemperature: number;
        cloudCover: number;
        dewpoint: number;
        humidity: number;
        icon: string; // currently icons
        ozone: number;
        precipIntensity: number;
        precipProbability: number;
        pressure: number;
        recently?: WeatherDataRecently[];
        summary: string;
        temperature: number;
        time: number;
        uvIndex: number;
        visibility: number;
        windbearing: number;
        windGust: number;
        windSpeed: number;
    } | null; // From the code, it looks like this isn't guaranteed.
}

export type WeatherUnits = {
    [key in keyof (WeatherData['currently'] & WeatherDataRecently)]: string;
};

// ========================================
// VUEX STATE
// These types are used in creation of
// vuex stores
// ========================================

export interface BaseState {
    selectedItem?: any;
    items?: any[];
    itemsPagination?: any;
    asyncStatus?: any;
}

export interface AccountState extends BaseState {
    currentSessionCheck: Promise<any> | null;
    visitorEmail: string;
    currentUser: CurrentUser | null;
    autoAuthStatus: boolean | null;
    userActivities: Activity[];
    userDevices: Device[];
}

export interface InvestigationsState extends BaseState {
    items: Investigation[];
}

export interface PostsState extends BaseState {
    byUniqueId: {
        [key: string]: {
            items: Post[],
            pagination: any,
            params: PostParameters,
        },
    };
    queryParams: {
        [key: string]: PostParameters;
    };
    mostRecent: Post[] | [];
    selectedPost: Post | null;
    lastPostData: LastPostData;
}

export interface StoriesState {
    fetchedPages: number;
    requestsInFlight: number;
    items: { [id: string]: Story };
}

export type LastPostData =  null | {
    investigationId: number;
    location: null | MBAddressObj;
    coordinates: null | LngLat
}

export enum CONTENT_ID {
    HOME = 'homepage',
    INVESTIGATION_INDEX = 'investigations_index_page',
    SENSORS = 'sensors',
    GENERIC_PAGE = 'generic_page',
}

export interface ContentState {
    locale: string,
    content: {
        [langAndTypeAndUid: string]: PrismicDocument;
    };
}

export interface RootState {
    apiClient: any;
    prompt: {
        onboard: boolean;
    };
    alerts: AlertData[];
    share: {
        shown: boolean;
        content: {
            dialogTitle?: string;
            path?: string;
            url?: string;
            title?: string;
            text?: string;
            post?: Post;
        };
    };
    overlay: {
        component: null | any;
        type: string;
        shown: boolean;
    };

    platform: '' | 'web' | 'android' | 'ios';
    partner: null | string,
    previousRoutes: any[];
    network: {
        connected: boolean;
        connectedType: string;
    };
    activeLocation: null | MBAddressObj;
    investigations: InvestigationsState;
    account: AccountState;
    content: ContentState;
    posts: PostsState;
    projects: ProjectsState;
    stories: StoriesState;
}
